.welcome-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.5s ease-in-out;
}

.welcome-content {
    background-color: white;
    opacity: 0.9; /* Makes the content slightly transparent */
    padding: 2rem;
    border-radius: 10px;
    max-width: 80%;
    text-align: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.welcome-content h1 {
    color: #333;
    margin-bottom: 1rem;
}

.welcome-content p {
    color: #444;
    margin-bottom: 1rem;
}

.welcome-content ul {
    text-align: left;
    margin-bottom: 1rem;
    padding-left: 2rem;
}

.welcome-content li {
    color: #444;
    margin-bottom: 0.5rem;
}

.welcome-content button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.welcome-content button:hover {
    background-color: #45a049;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}