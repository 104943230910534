.map-container {
    transition: width 0.3s ease-in-out;
    width: 100%;
    height: 100%;
  }

.map-container.panel-open {
    width: 70%; /* Adjust this to match 100% minus the panel width */
}

@media (max-width: 768px) {
    .map-container.panel-open {
        height: 50%;
    }
}