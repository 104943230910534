.floating-panel {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 25%; /* Adjust this value to change the panel width */
    max-width: 400px;
    background-color: white;
    padding: 1rem;
    box-shadow: -2px 0 10px rgba(0,0,0,0.1);
    overflow-y: auto;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.floating-panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #e0e0e0;
}

.floating-panel-header h2 {
    margin: 0;
    font-size: 1.5rem;
}

.floating-panel-header button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
}

.floating-panel-content {
    flex-grow: 1;
    overflow-y: auto;
    padding-right: 0.5rem;
}

.event-card {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 1rem;
    margin-bottom: 1rem;
    background-color: #f9f9f9;
}

.event-card h3 {
    margin-top: 0;
    color: #333;
    font-size: 1.2rem;
}

.event-card p {
    margin: 0.5rem 0;
    font-size: 0.9rem;
}

/* New styles for bold labels */
.event-card p strong {
    font-weight: bold;
}

.event-card details {
    margin-top: 1rem;
}

.event-card summary {
    cursor: pointer;
    color: #007bff;
    font-weight: bold;
}

.event-card a {
    color: #007bff;
    text-decoration: none;
}

.event-card a:hover {
    text-decoration: underline;
}

/* Responsive design for smaller screens */
/* @media (max-width: 768px) {
    .floating-panel {
        width: 100%;
        height: 50%;
        top: auto;
        bottom: 0;
    }
} */

@media (max-width: 768px) {
    .floating-panel {
        width: 100%; /* Full width on mobile */
        left: 0; 
        height: 100%; /* Full height */
    }

    .floating-panel-header h2 {
        font-size: 1.2rem; /* Smaller font size for mobile */
    }

    .floating-panel-header button {
        padding: 0.3rem 0.8rem; /* Smaller padding for the button */
        font-size: 0.9rem; /* Smaller font size for the button */
    }

    .event-card {
        padding: 0.8rem; /* Smaller padding for event cards on mobile */
    }

    .event-card h3 {
        font-size: 1rem; /* Smaller font size for event titles on mobile */
    }

    .event-card p {
        font-size: 0.8rem; /* Smaller font size for event details on mobile */
    }
}