.info-window-content {
    position: relative;
    padding: 15px;
    max-width: 300px;
    box-sizing: border-box;
}

.info-window-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
    position: relative;
}

.info-window-header h3 {
    margin: 0;
    padding-right: 20px; /* Make space for close button */
    font-size: 1.1em;
    word-break: break-word;
}

.calendar-dropdown {
    position: relative;
}

.add-to-calendar-text {
    cursor: pointer;
    color: #007bff;
    white-space: nowrap;
}

.add-to-calendar-text:hover {
    text-decoration: underline;
}

.dropdown-menu {
    position: absolute;
    right: 0;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.dropdown-menu span {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
}

.dropdown-menu span:hover {
    background-color: #f1f1f1;
}

.pre-registration-message {
    color: red;
    font-style: italic;
    font-size: 0.9em;
}

/* Style for the close button */
.gm-ui-hover-effect {
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
    width: 20px !important;
    height: 20px !important;
    padding: 0 !important;
    margin: 0 !important;
    background: none !important;
    z-index: 1 !important;
}

/* Remove default padding from the info window */
.gm-style .gm-style-iw-c {
    padding: 0 !important;
}

/* Remove the tail from the info window */
.gm-style .gm-style-iw-t::after {
    display: none !important;
}

@media (max-width: 768px) {
    .info-window-content {
        max-width: 250px;
        padding: 10px;
    }

    .info-window-header h3 {
        font-size: 1em;
    }

    .add-to-calendar-text {
        font-size: 0.9em;
    }

    .dropdown-menu {
        min-width: 140px;
    }

    .dropdown-menu span {
        padding: 10px 12px;
        font-size: 0.9em;
    }

    .pre-registration-message {
        font-size: 0.8em;
    }
}

@media (max-width: 320px) {
    .info-window-content {
        max-width: 200px;
        padding: 8px;
    }

    .info-window-header h3 {
        font-size: 0.9em;
    }

    .add-to-calendar-text {
        font-size: 0.8em;
    }

    .dropdown-menu {
        min-width: 120px;
    }

    .dropdown-menu span {
        padding: 8px 10px;
        font-size: 0.8em;
    }
}
