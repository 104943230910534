.filter-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px;
    background-color: #f5f5f5;
    border-radius: 8px;
}

.filter-row {
    display: flex;
    align-items: center;
    gap: 10px;
}

.filter-row label {
    font-weight: bold;
    min-width: 150px; /* Adjust this value to align all labels */
}

.filter-controls {
    display: flex;
    flex-grow: 1;
    gap: 10px;
}

.filter-row button,
.filter-row input,
.filter-row select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.filter-row button {
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    white-space: nowrap;
}

.filter-row button:hover {
    background-color: #0056b3;
}

.age-select,
input[name="type"] {
    flex-grow: 1;
}

.age-select {
    flex-grow: 1;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: white;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 24px;
    padding-right: 30px; /* Space for custom arrow */
}

/* Ensure consistent styling on iOS */
.age-select::-ms-expand {
    display: none;
}

@media (max-width: 768px) {
    .filter-row {
        flex-wrap: nowrap;
    }

    .filter-row label {
        min-width: 20%;
        margin-bottom: 2px;
    }

    .filter-controls {
        width: 100%;
    }

    .filter-row button,
    .filter-row input,
    .filter-row select {
        flex: 1 1 auto;
    }
}